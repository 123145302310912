import type { IWixAPI } from '@wix/yoshi-flow-editor';

export const registerToUrlChange = (
  wixCodeApi: IWixAPI,
  callback: Function,
  ...callbackParams: object[]
) => {
  wixCodeApi.location.onChange((e) => {
    callback(...callbackParams);
  });
};
