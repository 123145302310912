import type { TFunction } from '@wix/yoshi-flow-editor';
import model from './model';
import { ErrorModalController } from './ErrorModalController';
import { registerToUrlChange } from 'root/utils/navigationUtils';

export default model.createController(({ $bindAll, $widget, flowAPI }) => {
  const { translations, controllerConfig } = flowAPI;
  const window = controllerConfig.wixCodeApi.window;

  const { data, closeModal } = window.lightbox.getContext() || {};

  const t = translations.t as TFunction;

  const errorModalController = new ErrorModalController($bindAll, t, window);
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      registerToUrlChange(controllerConfig.wixCodeApi, closeModal, window);
      errorModalController.init(data, closeModal);
    },
    exports: {},
  };
});
