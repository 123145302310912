import { SECTION_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { PopulatedSection } from '../../types/menusTypes';
import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import { context } from '../../context/RootContext';
import type { SectionState } from '../../types/businessTypes';
import { SECTION_STATES } from '../../types/businessTypes';
import { navigationMenuState } from 'root/states/NavigationMenuState';

export class SectionsController {
  constructor(private $w: I$W, private t: TFunction) {
    context.pubsub.subscribe('onScrollToSection', this.scrollToSection);
  }

  setSections(sections: PopulatedSection[]) {
    this.$w(SECTION_WIDGET_COMPONENT_IDS.repeaterSections).data = sections.map(
      ({ id, ...rest }) => ({
        _id: id,
        ...rest,
      })
    );
  }

  init(sections: PopulatedSection[], menuId: string) {
    this.switchState(SECTION_STATES.sections);
    this.$w(SECTION_WIDGET_COMPONENT_IDS.repeaterSections).onItemReady(
      ($item: I$W, itemData: PopulatedSection & { _id: string }) => {
        const title = $item(SECTION_WIDGET_COMPONENT_IDS.sectionTitle);
        title.text = itemData?.name || '';
        if (!itemData?.name) {
          title.collapse();
        } else {
          title.collapsed && title.expand();
        }

        const description = $item(SECTION_WIDGET_COMPONENT_IDS.sectionDescription);
        description.text = itemData?.description || '';
        if (!itemData?.description) {
          description.collapse();
        } else {
          description.collapsed && description.expand();
        }

        const dishesContainer = $item(SECTION_WIDGET_COMPONENT_IDS.dishesContainer);
        dishesContainer.data = {
          itemIds: itemData?.itemIds,
          sectionId: itemData._id,
          menuId,
          size: itemData.size,
          isTruncated: itemData.truncated,
        };
        dishesContainer.onViewportEnter(() => {
          navigationMenuState.onSectionViewportEnter(itemData._id);
        });
        dishesContainer.onViewportLeave(() => {
          navigationMenuState.onSectionViewportLeave(itemData._id);
        });
      }
    );
    this.setSections(sections);
  }

  setEmptyState() {
    this.switchState(SECTION_STATES.sectionEmptystate);
    this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionEmptyStateTitle).text = this.t(
      'menu_olo.emptyState.title'
    );
    this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionEmptyStateSubtitle).text = this.t(
      'menu_olo.emptyState.subTitle'
    );
  }

  switchState(sectionState: SectionState) {
    const multiStateBox = this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionMultiStateBox);
    multiStateBox.changeState(sectionState);
  }

  scrollToSection = ({ sectionId }: { sectionId: string }) => {
    navigationMenuState.setNavigating(true);
    setTimeout(
      () =>
        this.$w(SECTION_WIDGET_COMPONENT_IDS.repeaterSections).forItems(
          [sectionId],
          async ($item: I$W) => {
            await $item(SECTION_WIDGET_COMPONENT_IDS.sectionTitle).scrollTo();
            navigationMenuState.setNavigating(false);
          }
        ),
      0
    );
  };
}
