import type { ISiteApis } from '@wix/yoshi-flow-editor';
import type {
  StructureLightbox,
  StructurePage,
} from '@wix/native-components-infra/dist/src/types/types';

type StructurePageWithHiddenFields = StructurePage & {
  applicationId: string;
  tpaPageId: string;
};

type StructureLightboxWithHiddenFields = StructureLightbox & {
  id: string;
  appDefinitionId: string;
  tpaPageId: string;
};

export type SiteStructureWithHiddenFields = {
  pages: StructurePageWithHiddenFields[];
  lightboxes: StructureLightboxWithHiddenFields[];
};

export const getSiteStructure = (site: ISiteApis): Promise<SiteStructureWithHiddenFields> => {
  return site.getSiteStructure({
    includePageId: true,
  }) as unknown as Promise<SiteStructureWithHiddenFields>;
};
