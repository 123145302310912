import type { IHttpClient } from '@wix/yoshi-flow-editor';
import { getMenu, queryMenus } from '@wix/ambassador-restaurants-menus-v1-menu/http';
import { SortOrder } from '@wix/ambassador-restaurants-menus-v1-menu/types';
import type { MenuTpa } from '../types/menusTypes';

export class MenusClient {
  httpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  fetchVisibleMenus = async (menuIds: string[] = []): Promise<{ menus: MenuTpa[] }> => {
    try {
      return this.httpClient
        .request(
          queryMenus({
            query: {
              filter: { id: { $in: menuIds }, visible: true },
              sort: [
                {
                  fieldName: 'createdDate',
                  order: SortOrder.ASC,
                },
              ],
            },
          })
        )
        .then((res) => {
          const {
            data: { menus = [] },
          } = res;

          return {
            menus,
          };
        });
    } catch (e: unknown) {
      console.error(e);
      return { menus: [] };
    }
  };

  getMenuById = async (menuId: string) => {
    try {
      return this.httpClient.request(getMenu({ menuId })).then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };
}
