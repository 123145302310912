import type { DisplayedPriceVariant } from '@wix/restaurants-populated-item-client/types';

export const getVariantById = ({
  priceVariantsInItem,
  variantId,
}: {
  priceVariantsInItem: DisplayedPriceVariant[];
  variantId: string;
}) => priceVariantsInItem.find((priceVariant) => priceVariant.id === variantId);

export const getVariantWithMinimalPrice = (
  variantListItems: DisplayedPriceVariant[]
): DisplayedPriceVariant =>
  variantListItems.reduce(
    (prev, curr) => (Number(prev.priceInfo?.price) <= Number(curr.priceInfo?.price) ? prev : curr),
    {} as DisplayedPriceVariant
  );
