import model from './model';
import type { HeaderWidgetProps } from '../../types/menusTypes';
import { type TFunction } from '@wix/yoshi-flow-editor';
import { HeaderController } from './headerController';
import { SPECS } from 'root/appConsts/experiments';
import { ANOTHER_TIMEZONE } from 'root/api/consts';
import { getSiteLocale } from 'root/utils/siteDataUtils';

export default model.createController(({ $bindAll, $w, $widget, flowAPI }) => {
  const { translations, controllerConfig, environment, formatAddress, experiments } = flowAPI;
  const t = translations.t as TFunction;
  const { wixCodeApi } = controllerConfig;
  const { locationId } = wixCodeApi.location.query ?? {};
  const { user, site } = controllerConfig.wixCodeApi;
  const isMemberLoggedIn = !!user.currentUser.loggedIn;
  const locale = getSiteLocale(flowAPI);
  const timezone = site.timezone || ANOTHER_TIMEZONE;

  const headerController = new HeaderController(
    $bindAll,
    $w,
    t,
    formatAddress,
    locale,
    timezone,
    isMemberLoggedIn,
    environment.isSSR,
    environment.isEditor,
    experiments.enabled(SPECS.enableMultiLocation)
  );

  headerController.init();

  const shouldInitHeaderTexts = (props: { data: HeaderWidgetProps }) => {
    const { headerTitle, headerDescription }: HeaderWidgetProps = props.data;
    return headerTitle || headerDescription;
  };

  $widget.onPropsChanged((_, nextProps) => {
    if (shouldInitHeaderTexts(nextProps)) {
      headerController.initHeaderTexts({ ...nextProps.data, t });
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      headerController.initHeaderTexts({ ...$widget.props.data, t });
    },
    exports: {},
  };
});
