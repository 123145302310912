import type { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';
import { ItemsClient } from './itemsClient';
import { MenusClient } from './menusClient';
import { SectionsClient } from './sectionsClient';
import type { PopulatedMenu, Item } from '../types/menusTypes';
import {
  convertMenusToMenuType,
  convertSectionsToSectionType,
  convertPopulatedItemsToItemType,
  getItemIds,
  populateMenu,
} from './utils/utils';
import { context } from 'root/context/RootContext';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { menusState } from 'root/states/MenusState';
import type { GetMenuIdsByOperation } from './PopulateMenuIdsByOperationClient';

export const PopulatedMenuClient = ({
  httpClient,
  experiments,
  msid = '',
  currency,
  sentry,
}: {
  httpClient: IHttpClient;
  experiments: Experiments;
  msid: string | undefined;
  currency: string;
  sentry?: ErrorMonitor;
}) => {
  const menusClient = new MenusClient(httpClient);
  const sectionsClient = new SectionsClient(httpClient);
  const itemsClient = new ItemsClient(httpClient);

  return {
    getPartial: async (menuIdsByOperationPromise: Promise<GetMenuIdsByOperation | undefined>) => {
      try {
        const menuIdsByOperation = (await menuIdsByOperationPromise)?.menuIdsByOperation;

        if (!menuIdsByOperation?.length) {
          sentry?.addBreadcrumb({
            category: 'get menus',
            message: 'menuIds is empty for selected operation',
          });
          sentry?.captureException(new Error(`menuIds is empty for ${msid}`));
          return;
        }

        const [menuResponse, sectionResponse] = await Promise.all([
          menusClient.fetchVisibleMenus(menuIdsByOperation),
          sectionsClient.fetchAllSections(),
        ]);

        const menus = convertMenusToMenuType({ menus: menuResponse.menus });
        const sections = convertSectionsToSectionType({ sections: sectionResponse.sections ?? [] });

        return menus.map((menu) => populateMenu(menu, sections)) as PopulatedMenu[];
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('fetch data failed', e);
        menusState.setHasError(true);
      }
    },
    getItems: async (menus: PopulatedMenu[]): Promise<Item[]> => {
      const { items } = await itemsClient.fetchAllItems(getItemIds(menus));

      const itemsWithoutImgSize =
        items?.filter(
          (item) => item.image && item.image.id && !(item.image.width || item.image.height)
        ) || [];

      const fileIds = itemsWithoutImgSize.map((item) => item.image!.id!);
      let imagesData;
      if (fileIds.length > 0) {
        context.biReporterService?.reportOloGenericDebugBiEvent({
          subjectType: 'fix width and height of images',
          value: {
            fileIds: JSON.stringify(fileIds),
            fileIdsLength: fileIds.length,
            msid,
          },
        });
        imagesData = await itemsClient.getImgsData(fileIds, msid);
      }

      return items ? convertPopulatedItemsToItemType({ items, currency, imagesData }) : [];
    },
  };
};
