import { DateTime } from 'luxon';
import type { DateTimeFormatOptions } from 'luxon';
import type { DispatchInfo } from 'root/types/businessTypes';

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

export const sortDates = (dates: DateTime[]) => {
  return dates.sort((a, b) => {
    return a.startOf('day').toMillis() - b.startOf('day').toMillis();
  });
};

export const isTheNextDay = (date: DateTime, nextDate: DateTime) => {
  return date.plus({ days: 1 }).hasSame(nextDate, 'day');
};

export const safeDateTimeConversion = (date: DateTime) => {
  return new Date(
    date.get('year'),
    date.get('month') - 1,
    date.get('day'),
    date.get('hour'),
    date.get('minute'),
    date.get('second'),
    date.get('millisecond')
  );
};

export const mergeDateRanges = (dates: DateTime[]): DateRange[] => {
  const dateRanges: DateRange[] = [];
  if (dates.length) {
    const sortedDates = sortDates(dates);
    let minDate = sortedDates[0];
    let maxDate = sortedDates[0];
    sortedDates.slice(1).forEach((date) => {
      if (isTheNextDay(maxDate, date)) {
        maxDate = date;
      } else {
        dateRanges.push({
          startDate: safeDateTimeConversion(minDate),
          endDate: safeDateTimeConversion(maxDate),
        });
        minDate = date;
        maxDate = date;
      }
    });
    dateRanges.push({
      startDate: safeDateTimeConversion(minDate),
      endDate: safeDateTimeConversion(maxDate),
    });
  }
  return dateRanges;
};

export const getMonthlyDateRange = (dispatchInfo: DispatchInfo) => {
  const firstAvailableDate = dispatchInfo.minDate!;
  const selectedTime = dispatchInfo.selectedTimeSlot?.startTime ?? firstAvailableDate;
  return {
    from: safeDateTimeConversion(selectedTime.startOf('month')),
    until: safeDateTimeConversion(selectedTime.endOf('month')),
  };
};

export const getBusinessDaysDatesRange = (timezone: string, businessDays: number) => {
  const today = DateTime.local({ zone: timezone }).startOf('day');

  return {
    from: safeDateTimeConversion(today),
    until: safeDateTimeConversion(today.plus({ days: businessDays }).endOf('day')),
  };
};

const DATE_FORMAT_MAP: Record<string, string> = {
  day: 'DD',
  month: 'MM',
  year: 'YYYY',
};

export const getDateFormat = (locale: string) => {
  const options: DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };

  const formatObj = new Intl.DateTimeFormat(locale, options).formatToParts(Date.now());

  return formatObj
    .map((obj) => DATE_FORMAT_MAP[obj.type] ?? '')
    .filter((char) => char)
    .join('/');
};
