import type { ItemImage as Image } from '@wix/restaurants-populated-item-client/types';
import type { TFunction } from '@wix/yoshi-flow-editor';
import type { CartLineItem } from '../../services/cartService';

export const buildImgSrc: (image: Image) => string = ({ width, height, id }) => {
  return `wix:image://v1/${id}/#originWidth=${width}&originHeight=${height}`;
};

export const getAltText = ({ itemName = '', t }: { itemName?: string; t: TFunction }) => {
  return t('menu_olo.itemImage.altText', { dishName: itemName });
};

export const isItemValidInCart = (cartItems?: CartLineItem[]) => {
  return !!cartItems?.some((item) => (item.quantity ?? 0) > 0);
};
