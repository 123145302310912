import type { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  addToCurrentCart,
  createCart,
  currentCartGetCurrentCart,
  removeLineItemsFromCurrentCart,
  updateCurrentCart,
} from '@wix/ambassador-ecom-v1-cart/http';
import type {
  AddToCurrentCartRequest,
  GetCurrentCartRequest,
  RemoveLineItemsFromCurrentCartRequest,
  AddToCartResponse,
  GetCurrentCartResponse,
  RemoveLineItemsResponse,
  SelectedShippingOption,
  Address,
  UpdateCartRequest,
  UpdateCartResponse,
  CreateCartRequest,
  CreateCartResponse,
} from '@wix/ambassador-ecom-v1-cart/types';
import type { FedopsLogger } from '../utils/monitoring/FedopsLogger';
import { getMonitoredApiCall } from './utils/getMonitoredApiCall';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { withMonitorRequestIdApiCall } from './utils/withMonitorRequestIdApiCall';

export interface ShippingRequest {
  shippingOption: SelectedShippingOption;
  address?: Address;
}

const MONITOR_TYPE = 'cart';

const CART_SENTRY_CATEGORY = {
  CREATE_CART: 'createCart',
  ADD_ITEM: 'addItemToCart',
  GET_CURRENT_CART: 'getCurrentCart',
  REMOVE_ITEM: 'removeItemFromCart',
  UPDATE_CART_INFO: 'updateCartInfo',
};

export interface ICartClient {
  createCart: (request: CreateCartRequest) => Promise<CreateCartResponse>;
  addItemToCart: (request: AddToCurrentCartRequest) => Promise<AddToCartResponse>;
  getCurrentCart: (request: GetCurrentCartRequest) => Promise<GetCurrentCartResponse>;
  removeItemsFromCart: (
    item: RemoveLineItemsFromCurrentCartRequest
  ) => Promise<RemoveLineItemsResponse>;
  updateCartInfo: (updateCartRequest: UpdateCartRequest) => Promise<UpdateCartResponse>;
}

export const CartClient = (
  httpClient: IHttpClient,
  fedopsLogger: FedopsLogger,
  sentry: ErrorMonitor
): ICartClient => ({
  createCart: async (request: CreateCartRequest) => {
    try {
      const { data = {} } = await getMonitoredApiCall({
        callback: () =>
          fetchAndMonitorRequestId<CreateCartRequest, CreateCartResponse>({
            httpClient,
            ambassadorApi: createCart,
            request,
            sentry,
            sentryCategory: CART_SENTRY_CATEGORY.ADD_ITEM,
          }),
        fedops: {
          start: fedopsLogger.createCartCallStarted,
          end: fedopsLogger.createCartCallEnded,
        },
        sentry,
      });
      return data;
    } catch (e: unknown) {
      // already reported inside the getMonitoredApiCall function
      return {};
    }
  },
  addItemToCart: async (request: AddToCurrentCartRequest) => {
    try {
      const { data = {} } = await getMonitoredApiCall({
        callback: () =>
          fetchAndMonitorRequestId<AddToCurrentCartRequest, AddToCartResponse>({
            httpClient,
            ambassadorApi: addToCurrentCart,
            request,
            sentry,
            sentryCategory: CART_SENTRY_CATEGORY.ADD_ITEM,
          }),
        fedops: {
          start: fedopsLogger.addItemsToCartCallStarted,
          end: fedopsLogger.addItemsToCartCallEnded,
        },
        sentry,
      });
      return data;
    } catch (e: unknown) {
      // already reported inside the getMonitoredApiCall function
      return {};
    }
  },
  getCurrentCart: async (request: GetCurrentCartRequest) => {
    try {
      const { data = {} } = await getMonitoredApiCall({
        callback: () =>
          fetchAndMonitorRequestId<GetCurrentCartRequest, GetCurrentCartResponse>({
            httpClient,
            ambassadorApi: currentCartGetCurrentCart,
            request,
            sentry,
            sentryCategory: CART_SENTRY_CATEGORY.GET_CURRENT_CART,
          }),
        fedops: {
          start: fedopsLogger.getCartItemsStarted,
          end: fedopsLogger.getCartItemsEnded,
        },
        sentry,
        excludeErrorsFromSentry: ['Request failed with status code 404'],
      });
      return data;
    } catch (e: unknown) {
      // already reported inside the getMonitoredApiCall function
      return {};
    }
  },
  removeItemsFromCart: async (items: RemoveLineItemsFromCurrentCartRequest) => {
    try {
      const { data = {} } = await getMonitoredApiCall({
        callback: () =>
          fetchAndMonitorRequestId<RemoveLineItemsFromCurrentCartRequest, RemoveLineItemsResponse>({
            httpClient,
            ambassadorApi: removeLineItemsFromCurrentCart,
            request: items,
            sentry,
            sentryCategory: CART_SENTRY_CATEGORY.REMOVE_ITEM,
          }),
        fedops: {
          start: fedopsLogger.removeItemFromCartCallStarted,
          end: fedopsLogger.removeItemFromCartCallEnded,
        },
        sentry,
      });
      return data;
    } catch (e: unknown) {
      // already reported inside the getMonitoredApiCall function
      return {};
    }
  },
  updateCartInfo: async (updateCartRequest: UpdateCartRequest) => {
    try {
      const { data = {} } = await getMonitoredApiCall({
        callback: () =>
          fetchAndMonitorRequestId<UpdateCartRequest, UpdateCartResponse>({
            httpClient,
            ambassadorApi: updateCurrentCart,
            request: updateCartRequest,
            sentry,
            sentryCategory: CART_SENTRY_CATEGORY.UPDATE_CART_INFO,
          }),
        fedops: {
          start: fedopsLogger.updateCartStarted,
          end: fedopsLogger.updateCartEnded,
        },
        sentry,
      });

      return data;
    } catch (e: unknown) {
      // already reported inside the getMonitoredApiCall function
      return {};
    }
  },
});

const fetchAndMonitorRequestId = <T, R>({
  httpClient,
  ambassadorApi,
  request,
  sentry,
  sentryCategory,
}: {
  httpClient: IHttpClient;
  ambassadorApi: Function;
  request: T;
  sentry: ErrorMonitor;
  sentryCategory: string;
}): Promise<R> =>
  withMonitorRequestIdApiCall<R>({
    sentry,
    responsePromise: httpClient.request(ambassadorApi(request)),
    type: MONITOR_TYPE,
    category: sentryCategory,
  }).then((response) => response.data);
