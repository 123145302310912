import type model from './model';
import { POPULAR_DISHES_REPEATER_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import type { ControllerParams } from '../../types/widgets';
import type { ItemData } from 'root/types/item';
import { buildImgSrc, getAltText } from '../Dishes/utils';
import { getVariantWithMinimalPrice } from 'root/utils/priceVariantsUtils';
import { context } from 'root/context/RootContext';
import { LABELS_LIMIT } from 'root/api/consts';
import { dishes } from './mocks';

type BindAll = ControllerParams<typeof model>['$bindAll'];

export class PopularDishesController {
  constructor(private $bindAll: BindAll, private $w: I$W, private t: TFunction) {}

  async init() {
    this.$w('#slider').setAttribute('item', this.$w('#sliderRepeater').uniqueId);
    this.$w('#slider').setAttribute('arrow-left', this.$w('#leftArrowButton').uniqueId);
    this.$w('#slider').setAttribute('arrow-right', this.$w('#rightArrowButton').uniqueId);

    this.$bindAll({
      [POPULAR_DISHES_REPEATER_WIDGET_COMPONENT_IDS.popularDishesRepeater]: {
        // @ts-expect-error
        data: () =>
          dishes.map(({ id, ...rest }, idx) => ({
            _id: id,
            idx,
            ...rest,
          })),
        // @ts-expect-error
        item: (itemData: ItemData & { _id: string; idx: number }, bindItem) => {
          const imageProps = itemData.image?.url
            ? {
                src: () => (itemData.image && buildImgSrc(itemData.image)) ?? '',
                alt: () => getAltText({ itemName: itemData.name, t: this.t }),
              }
            : {};

          bindItem(POPULAR_DISHES_REPEATER_WIDGET_COMPONENT_IDS.dishName, {
            text: () => {
              return itemData.name || '';
            },
            collapsed: () => false,
          });

          bindItem(POPULAR_DISHES_REPEATER_WIDGET_COMPONENT_IDS.dishPrice, {
            text: () => {
              const amount = itemData.priceVariants
                ? getVariantWithMinimalPrice(itemData.priceVariants?.variants || [])?.priceInfo
                    .price
                : itemData.price.amount;
              const formattedPrice = context.priceFormatter(Number(amount));

              return formattedPrice || '';
            },
            collapsed: () => false,
          });

          bindItem(POPULAR_DISHES_REPEATER_WIDGET_COMPONENT_IDS.dishImg, {
            ...imageProps,
            collapsed: () => !itemData.image?.url,
          });

          const hasLabels = itemData.labels?.length;
          const { label, additionalLabelsCounter, labelContainer } =
            POPULAR_DISHES_REPEATER_WIDGET_COMPONENT_IDS;

          bindItem(labelContainer, {
            hidden: () => !hasLabels,
          });

          hasLabels && this.initLabels(itemData, bindItem, label, additionalLabelsCounter);
        },
      },
    });
  }

  initLabels(
    itemData: ItemData,
    // @ts-expect-error
    bindItem,
    getLabelElement: (idx: number) => string,
    additionalLabelsCounterId: string
  ) {
    for (let i = 0; i < LABELS_LIMIT; i++) {
      const currentLabel = itemData.labels?.[i];
      const currentLabelElement = getLabelElement(i + 1);

      bindItem(currentLabelElement, {
        collapsed: () => !currentLabel,
      });

      if (currentLabel) {
        const iconSrc = currentLabel.icon?.url ? { src: () => currentLabel.icon?.url } : {};

        bindItem(currentLabelElement, {
          ...iconSrc,
          accessibility: {
            ariaAttributes: {
              label: () => currentLabel.name ?? '',
            },
          },
          alt: () => currentLabel.name ?? '',
          collapsed: () => !currentLabel?.icon?.url,
        });
      }
    }

    const numOfAdditionalLabels = itemData.labels.length - LABELS_LIMIT;
    const showLabelCounter = itemData.labels.length > LABELS_LIMIT;
    const PLUS_SIGN = '+';
    bindItem(additionalLabelsCounterId, {
      text: () => (showLabelCounter ? `${PLUS_SIGN}${numOfAdditionalLabels}` : ''),
      collapsed: () => !showLabelCounter,
      hidden: () => !showLabelCounter,
    });
  }
}
