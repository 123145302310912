import type { ControllerParams, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type model from './model';
import { GHOST_STATE_COMPONENT_IDS } from 'root/appConsts/blocksIds';
import { context } from 'root/context/RootContext';
import { LiveSiteClickFulfillmentOrigin } from '@wix/restaurants-bi';
import { openDispatchModal } from 'root/utils/utils';
import { dispatchState } from 'root/states/DispatchState';
import { menusState } from 'root/states/MenusState';

// @ts-expect-error
type BindAll = ControllerParams<typeof model>['$bindAll'];

export class GhostStateController {
  private t: PlatformControllerFlowAPI['translations']['t'];
  private isMemberLoggedIn: boolean;
  private isEditor: boolean;

  constructor(private bindAll: BindAll, flowAPI: PlatformControllerFlowAPI) {
    const {
      environment,
      translations: { t },
      controllerConfig,
    } = flowAPI;

    const { user } = controllerConfig.wixCodeApi;
    this.isMemberLoggedIn = !!user.currentUser.loggedIn;
    this.isEditor = environment.isEditor;
    this.t = t;
  }

  async init() {
    this.bindAll({
      [GHOST_STATE_COMPONENT_IDS.container]: {
        deleted: () => this.isEditor,
        collapsed: () =>
          dispatchState.isLoading ||
          !dispatchState.isMultiLocation ||
          !!dispatchState.isLocationSelected,
      },
      [GHOST_STATE_COMPONENT_IDS.label]: {
        text: () => this.t('header_olo.multiLocationNotification'),
      },
      [GHOST_STATE_COMPONENT_IDS.button]: {
        onClick: async () => {
          context.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
            origin: LiveSiteClickFulfillmentOrigin.START_ORDER,
            isMemberLoggedIn: this.isMemberLoggedIn,
          });
          openDispatchModal({
            onSave: async ({ dispatchType, dispatchInfo, operationId, isLocationChanged }) => {
              dispatchState.setCurrentOperationId(operationId);
              dispatchState.setIsLocationSelected(true);
              dispatchState.update(dispatchType, dispatchInfo);
              if (isLocationChanged) {
                await context.CartService?.clearCart();
              }
              context.CartService?.setShippingDetails(dispatchState.getShippingDetails());
              await menusState.updateAvailabilityStatus(
                dispatchState.currentOperationId,
                dispatchInfo,
                dispatchType
              );
            },
            context,
            dispatchState: dispatchState.state,
          });
        },
        disabled: () => dispatchState.isLoading,
        label: () => this.t('header_olo.startOrderButton'),
      },
    });
  }
}
