import { GhostStateController } from './ghostStateController';
import model from './model';

export default model.createController(({ $bindAll, $widget, flowAPI }) => {
  const controller = new GhostStateController($bindAll, flowAPI);

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      controller.init();
    },
    exports: {},
  };
});
