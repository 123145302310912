import type { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { OrdersSettingsClient } from './ordersSettingsClient';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import type { ReportError } from './utils/getMonitoredApiCall';
import { menusState } from 'root/states/MenusState';
import type { MenuAvailability } from '@wix/restaurants-olo-operations-client-commons';
import { MenuOrderingSettingsConverter } from '@wix/restaurants-olo-operations-client-commons';

export type GetMenuIdsByOperation = {
  menuIdsByOperation: string[] | undefined;
  menusAvailability: Record<string, MenuAvailability>;
};

export const PopulateMenuIdsByOperationClient = ({
  httpClient,
  experiments,
  reportError,
  sentry,
  fedopsLogger,
  timezoneOffset = 0,
}: {
  httpClient: IHttpClient;
  experiments: Experiments;
  reportError?: ReportError;
  sentry?: ErrorMonitor;
  fedopsLogger?: FedopsLogger;
  timezoneOffset?: number;
}) => {
  const ordersSettingsClient = new OrdersSettingsClient(
    httpClient,
    sentry,
    fedopsLogger,
    reportError
  );

  return {
    getAll: async (
      operationIdPromise?: Promise<string | undefined>
    ): Promise<GetMenuIdsByOperation | undefined> => {
      try {
        const operationId = await operationIdPromise;

        const fetchMenuIdsByOperation = async () => {
          const response = await ordersSettingsClient.getMenuOrderingSettingsOperationId(
            operationId,
            () => {
              menusState.setHasError(true);
            }
          );

          return response.menuOrderingSettings || [];
        };

        const menuOrderingSettings = operationId ? await fetchMenuIdsByOperation() : undefined;
        const menusAvailability: Record<string, MenuAvailability> = {};

        menuOrderingSettings?.forEach((setting) => {
          const mos = MenuOrderingSettingsConverter.fromServerModel(setting, timezoneOffset, false);
          const menuId = mos.menuId;
          const availability = mos.availability;

          if (menuId && availability) {
            menusAvailability[menuId] = availability;
          }
        });

        return {
          menuIdsByOperation: Object.keys(menusAvailability),
          menusAvailability,
        };
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('fetch menuOrdersSettings failed', e);
        menusState.setHasError(true);
      }
    },
  };
};
