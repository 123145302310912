import { makeAutoObservable, configure } from 'mobx';
import { DEFAULT_CURRENCY_CODE } from '../api/consts';
import type { DisplayedPriceVariant } from '@wix/restaurants-populated-item-client/types';
import type { ModifierListItem } from 'root/types/modifiers';

configure({ isolateGlobalState: true });

export const DEFAULT_QUANTITY = 1;
const DEFAULT_AMOUNT = 0;
const DEFAULT_ADDITIONAL_CHARGE = 0;

export const state = makeAutoObservable<{
  quantity: number;
  amount: number;
  currency: string;
  basePrice?: number;
  additionalCharge: number;
  selectedPriceVariant: DisplayedPriceVariant;
  specialRequests: string;
  selectedModifiers: Record<string, ModifierListItem[]>;
  disabledModifiers: number[];
  modifiersErrors: Record<string, boolean>;
}>({
  quantity: DEFAULT_QUANTITY,
  amount: DEFAULT_AMOUNT,
  currency: DEFAULT_CURRENCY_CODE,
  specialRequests: '',
  additionalCharge: DEFAULT_ADDITIONAL_CHARGE,
  selectedModifiers: {},
  disabledModifiers: [],
  selectedPriceVariant: {} as DisplayedPriceVariant,
  modifiersErrors: {},
});
