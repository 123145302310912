import type { EditorSDK } from '@wix/platform-editor-sdk';
import { DEFAULT_CURRENCY_CODE, DEFAULT_LOCALE } from '../api/consts';
import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const getSiteCurrency = (flowAPI: PlatformControllerFlowAPI) => {
  const { controllerConfig } = flowAPI;
  const currencyCode = controllerConfig.wixCodeApi.site.currency || DEFAULT_CURRENCY_CODE;

  return currencyCode;
};

export const getSiteCurrencyByEditorSDK = (editorSDK: EditorSDK | null) => {
  const { info } = editorSDK ?? {};
  return info?.getCurrency('token') ?? DEFAULT_CURRENCY_CODE;
};

export const getSiteLocale = (flowAPI: PlatformControllerFlowAPI) => {
  const { site, window } = flowAPI.controllerConfig.wixCodeApi;
  const { currentLanguage, siteLanguages, isEnabled } = window.multilingual;

  const multilingualLocale =
    isEnabled && siteLanguages?.find((lng) => lng.languageCode === currentLanguage)?.locale;

  return multilingualLocale || site.regionalSettings || DEFAULT_LOCALE;
};
