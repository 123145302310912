import type { HttpResponse } from '@wix/yoshi-flow-editor';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';

export const withMonitorRequestIdApiCall = async <T>({
  sentry,
  responsePromise,
  type,
  category,
}: {
  sentry: ErrorMonitor;
  responsePromise: Promise<HttpResponse<T>>;
  type: string;
  category: string;
}) => {
  const response = await responsePromise;
  sentry?.addBreadcrumb({
    type,
    category,
    message: `requestId: ${response.requestId}`,
  });
  return response;
};
