import type { Item } from 'root/types/menusTypes';

export const dishes: Item[] = [
  {
    id: '1',
    additionalImages: [],
    description: 'Sourdough bread accompanied by hummus, beetroot & whipped feta dips',
    image: {
      id: 'ec845c_bfcda5f9249c4229b29012f98be80c7f~mv2.jpg',
      url: 'https://static.wixstatic.com/media/ec845c_bfcda5f9249c4229b29012f98be80c7f~mv2.jpg',
      height: 5464,
      width: 8192,
    },
    labels: [
      {
        icon: {
          id: 'ec845c_1e2d7b43595d4211a518c7876faa837c.svg',
          url: 'https://static.wixstatic.com/shapes/ec845c_1e2d7b43595d4211a518c7876faa837c.svg',
          height: 24,
          width: 24,
        },
        id: 'c05c3ba3-0917-4257-ac8b-4677a754bfbb',
        name: 'Vegetarian',
        revision: '1',
      },
    ],
    modifierGroups: [],
    name: 'Bread & dips',
    orderSettings: {
      inStock: true,
      taxGroupId: '9a5d83fd-8570-482e-81ab-cfa88942ee60',
      acceptSpecialRequests: true,
    },
    price: { amount: 4.5, currency: 'EUR' },
    revision: '1',
    visible: true,
  },
  {
    id: '2',
    additionalImages: [],
    description: 'Sourdough bread accompanied by hummus, beetroot & whipped feta dips',
    image: {
      height: 3335,
      id: 'ec845c_bfb0d41ee594410cba7737e55da0f447~mv2.jpg',
      url: 'https://static.wixstatic.com/media/ec845c_bfb0d41ee594410cba7737e55da0f447~mv2.jpg',
      width: 5000,
    },
    labels: [],
    modifierGroups: [],
    name: 'Classic burger',
    orderSettings: {
      inStock: true,
      taxGroupId: '9a5d83fd-8570-482e-81ab-cfa88942ee60',
      acceptSpecialRequests: true,
    },
    price: { amount: 1.5, currency: 'EUR' },
    revision: '1',
    visible: true,
  },
  {
    id: '3',
    additionalImages: [],
    description: 'Sourdough bread accompanied by hummus, beetroot & whipped feta dips',
    image: {
      height: 5464,
      id: 'ec845c_c46d8fff4c8e410c816429ce6148acd8~mv2.jpg',
      url: 'https://static.wixstatic.com/media/ec845c_c46d8fff4c8e410c816429ce6148acd8~mv2.jpg',
      width: 8192,
    },
    labels: [
      {
        icon: {
          id: 'ec845c_03ef22be17f54eddb1499c5a56033bfe.svg',
          url: 'https://static.wixstatic.com/shapes/ec845c_03ef22be17f54eddb1499c5a56033bfe.svg',
          height: 24,
          width: 24,
        },
        id: '8ae60884-8a6e-4813-8cf4-b41ae3aeac54',
        name: 'Dairy free',
        revision: '1',
      },
    ],
    modifierGroups: [],
    name: 'Brownie',
    orderSettings: {
      inStock: true,
      taxGroupId: '9a5d83fd-8570-482e-81ab-cfa88942ee60',
      acceptSpecialRequests: true,
    },
    price: { amount: 5, currency: 'EUR' },
    revision: '1',
    visible: true,
  },
  {
    id: '4',
    additionalImages: [],
    description: 'Sourdough bread accompanied by hummus, beetroot & whipped feta dips',
    image: {
      height: 3335,
      id: 'ec845c_5956a101ab344a2aa6181b3124131afc~mv2.jpg',
      url: 'https://static.wixstatic.com/media/ec845c_5956a101ab344a2aa6181b3124131afc~mv2.jpg',
      width: 5000,
    },
    labels: [
      {
        icon: {
          id: 'ec845c_aec44ab07aaf49a6b76a2d53c6dea7f4.svg',
          url: 'https://static.wixstatic.com/shapes/ec845c_aec44ab07aaf49a6b76a2d53c6dea7f4.svg',
          height: 24,
          width: 24,
        },
        id: '678d3531-4936-4060-a6fe-1b65c16569bc',
        name: 'Fish',
        revision: '1',
      },
      {
        icon: {
          id: 'ec845c_535d716f22a24816a3e2c42eee460630.svg',
          url: 'https://static.wixstatic.com/shapes/ec845c_535d716f22a24816a3e2c42eee460630.svg',
          height: 24,
          width: 24,
        },
        id: 'd1cee03f-dea8-4eb2-b7eb-fa529b410a7b',
        name: 'Shellfish',
        revision: '1',
      },
    ],
    modifierGroups: [],
    name: 'Fish of the day',
    orderSettings: {
      inStock: true,
      taxGroupId: '9a5d83fd-8570-482e-81ab-cfa88942ee60',
      acceptSpecialRequests: true,
    },
    price: { amount: 15, currency: 'EUR' },
    revision: '1',
    visible: true,
  },
  {
    id: '5',
    additionalImages: [],
    description: 'Sourdough bread accompanied by hummus, beetroot & whipped feta dips',
    image: {
      height: 3335,
      id: 'ec845c_3cebda0fe5e34038b72c9c899b5b6d53~mv2.jpg',
      url: 'https://static.wixstatic.com/media/ec845c_3cebda0fe5e34038b72c9c899b5b6d53~mv2.jpg',
      width: 5000,
    },
    labels: [],
    modifierGroups: [],
    name: 'Schnitzel',
    orderSettings: {
      inStock: true,
      taxGroupId: '9a5d83fd-8570-482e-81ab-cfa88942ee60',
      acceptSpecialRequests: true,
    },
    price: { amount: 8, currency: 'EUR' },
    revision: '1',
    visible: true,
  },
];
