import type { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  queryMenuOrderingSettings,
  listMenusAvailabilityStatus,
} from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/http';
import type { TimeSlot } from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/types';
import type { FedopsLogger } from '../utils/monitoring/FedopsLogger';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import type { ReportError } from './utils/getMonitoredApiCall';
import { getMonitoredApiCall } from './utils/getMonitoredApiCall';
import { listFirstAvailableTimeSlotsForMenus } from '@wix/ambassador-restaurants-operations-v1-operation/http';
import type { Address } from '@wix/ambassador-restaurants-operations-v1-operation/types';

export class OrdersSettingsClient {
  httpClient;
  fedopsLogger;
  sentry;
  reportError;

  constructor(
    httpClient: IHttpClient,
    sentry?: ErrorMonitor,
    fedopsLogger?: FedopsLogger,
    reportError?: ReportError
  ) {
    this.httpClient = httpClient;
    this.fedopsLogger = fedopsLogger;
    this.sentry = sentry;
    this.reportError = reportError;
  }

  getMenuOrderingSettingsOperationId = async (
    operationId: string | undefined,
    onError?: (error: Error) => void
  ) => {
    const { data = {} } = await getMonitoredApiCall({
      callback: () =>
        this.httpClient
          .request(
            queryMenuOrderingSettings({
              query: { filter: { operationId, onlineOrderingEnabled: true } },
            })
          )
          .then((response) => response.data),
      fedops: {
        start: this.fedopsLogger?.fetchMenuOrdersSettingsStarted,
        end: this.fedopsLogger?.fetchMenuOrdersSettingsEnded,
      },
      sentry: this.sentry,
      reportError: this.reportError,
      onError,
    });

    return data;
  };

  listMenusAvailabilityStatusByTimeslot = async (
    operationId: string,
    timeSlot: TimeSlot,
    onError?: (error: Error) => void
  ) => {
    const { data = {} } = await getMonitoredApiCall({
      callback: () =>
        this.httpClient
          .request(listMenusAvailabilityStatus({ operationId, timeSlot }))
          .then((response) => response.data),
      fedops: {
        start: this.fedopsLogger?.listMenusAvailabilityStatusByTimeslotStarted,
        end: this.fedopsLogger?.listMenusAvailabilityStatusByTimeslotEnded,
      },
      sentry: this.sentry,
      reportError: this.reportError,
      onError,
    });

    return data;
  };

  listFirstAvailableTimeSlotsForMenus = async ({
    deliveryAddress,
    operationId = '',
    onError,
  }: {
    operationId?: string;
    deliveryAddress?: Address;
    onError?: (error: Error) => void;
  }) => {
    const { data = {} } = await getMonitoredApiCall({
      callback: () =>
        this.httpClient
          .request(
            listFirstAvailableTimeSlotsForMenus({
              operationId,
              deliveryAddress,
            })
          )
          .then((response) => response.data),
      fedops: {
        start: this.fedopsLogger?.listFirstAvailableTimeSlotsForMenusStarted,
        end: this.fedopsLogger?.listFirstAvailableTimeSlotsForMenusEnded,
      },
      sentry: this.sentry,
      reportError: this.reportError,
      onError,
    });

    return data;
  };
}
