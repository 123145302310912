import type { ModifierListItem } from '../types/modifiers';

export const sumAdditionalCharges = (selectedModifiers: Record<string, ModifierListItem[]>) => {
  const modifiers = Object.values(selectedModifiers).flat(1);
  const additionalChargesPerModifier = modifiers.map(
    (modifier) => Number(modifier.additionalChargeInfo?.additionalCharge) ?? 0
  );
  return additionalChargesPerModifier.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
};
