import type model from './model';
import type { IWixWindow, TFunction } from '@wix/yoshi-flow-editor';
import { ERROR_MODAL_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { ErrorModalProps } from '../../types/widgetsProps';
import type { ControllerParams } from 'root/types/widgets';

type BindAll = ControllerParams<typeof model>['$bindAll'];

export class ErrorModalController {
  constructor(private $bindAll: BindAll, private t: TFunction, private window: IWixWindow) {}

  init(props: ErrorModalProps, closeModal: (window: IWixWindow) => void) {
    if (!props) {
      return;
    }
    const { title, content, closeButtonLabel } = props;
    this.$bindAll({
      [ERROR_MODAL_COMPONENT_IDS.title]: {
        text: () => title,
      },
      [ERROR_MODAL_COMPONENT_IDS.content]: {
        text: () => content,
      },
      [ERROR_MODAL_COMPONENT_IDS.close]: {
        label: () => closeButtonLabel,
        onClick: () => closeModal(this.window),
      },
    });
  }
}
