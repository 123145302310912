export type AvailabilityStatusKeys = {
  dateRangeText: string;
  dateText: string;
  weeklyScheduleText: string;
};

export type MenuAvailabilityStatusKeys = {
  hasNextAvailabilityKeys: AvailabilityStatusKeys;
  noNextAvailabilityKeys: AvailabilityStatusKeys;
};

export type ItemModalAvailabilityStatusKeys = {
  hasNextAvailabilityKeys: AvailabilityStatusKeys;
  noNextAvailabilityKeys: AvailabilityStatusKeys;
};

export type AvailabilityStatusKeysStructure = {
  menu: MenuAvailabilityStatusKeys;
  itemModal: ItemModalAvailabilityStatusKeys;
};

export const availabilityStatusKeys = {
  menu: {
    hasNextAvailabilityKeys: {
      dateRangeText: 'menu_olo.menuAvailabilityStatus.dateRange.text',
      dateText: 'menu_olo.menuAvailabilityStatus.oneDate.text',
      weeklyScheduleText: 'menu_olo.menuAvailabilityStatus.weeklySchedule.text',
    },
    noNextAvailabilityKeys: {
      dateRangeText: 'menu_olo.menuAvailabilityStatus.dateRange.noNextAvailability.text',
      dateText: 'menu_olo.menuAvailabilityStatus.oneDate.noNextAvailability.text',
      weeklyScheduleText: 'menu_olo.menuAvailabilityStatus.weeklySchedule.noNextAvailability.text',
    },
  },
  itemModal: {
    hasNextAvailabilityKeys: {
      dateRangeText: 'itemModal.menuAvailabilityStatus.dateRange.canUpdateTime.text',
      dateText: 'itemModal.menuAvailabilityStatus.oneDate.canUpdateTime.text',
      weeklyScheduleText: 'itemModal.menuAvailabilityStatus.weeklySchedule.canUpdateTime.text',
    },
    noNextAvailabilityKeys: {
      dateRangeText: 'itemModal.menuAvailabilityStatus.dateRange.noNextAvailability.text',
      dateText: 'itemModal.menuAvailabilityStatus.oneDate.noNextAvailability.text',
      weeklyScheduleText: 'itemModal.menuAvailabilityStatus.weeklySchedule.noNextAvailability.text',
    },
  },
};
