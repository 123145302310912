import type { DispatchState, DispatchStateByLocation } from '../../types/businessTypes';
import { PersistStorageKeys, PersistStorageService } from '../persistStorageService';
import type { SerializedDispatchState } from '../serialization/DispatchStateSerializer';
import { DispatchStateSerializer } from '../serialization/DispatchStateSerializer';

export interface IPersistDataService {
  getDispatchState: (locations: string[]) => DispatchStateByLocation | undefined;
  setDispatchState: (state: DispatchStateByLocation) => void;
}

export const PersistDataService = (
  storage: {
    getItem(key: string): string | null;
    setItem(key: string, value: string): void;
    removeItem(key: string): void;
  },
  isEditor: boolean
): IPersistDataService => {
  const persistStorageService = PersistStorageService(storage);
  const returnObject = {
    getDispatchState: (locations: string[]): DispatchStateByLocation | undefined => {
      if (isEditor) {
        return;
      }
      try {
        const dispatchStateEntity: DispatchStateByLocation = new Map<string, DispatchState>();
        for (const location of locations ?? []) {
          const dispatchKey = `${PersistStorageKeys.dispatchState}-${location}`;
          const serialized = persistStorageService.getItem<SerializedDispatchState>(dispatchKey);
          if (!serialized) {
            continue;
          }
          dispatchStateEntity.set(location, DispatchStateSerializer.deserialize(serialized));
        }
        return dispatchStateEntity;
      } catch (e) {
        console.error('getDispatchState error :>> ', e);
      }
    },
    setDispatchState: (state: DispatchStateByLocation) => {
      if (state instanceof Map) {
        for (const [key, value] of state.entries()) {
          const dispatchKey = `${PersistStorageKeys.dispatchState}-${key}`;
          persistStorageService.removeItem(dispatchKey);
          if (value) {
            persistStorageService.setItem<SerializedDispatchState>(
              dispatchKey,
              DispatchStateSerializer.serialize(value)
            );
          }
        }
      }
    },
  };
  return returnObject;
};
