import { DateTime } from 'luxon';
import type { DispatchInfo, TimeSlot } from 'root/types/businessTypes';
import type { Serializer } from './types';

export type SerializedTimeSlot = Omit<TimeSlot, 'startTime' | 'endTime'> & {
  startTime: string;
  endTime: string;
};

export type SerializedDispatchInfo = Omit<DispatchInfo, 'minDate' | 'selectedTimeSlot'> & {
  minDate?: string;
  selectedTimeSlot?: SerializedTimeSlot | undefined;
};

/**
 * Serializes and deserializes DispatchInfo objects (specifically, handles DateTime serialization)
 */
export const DispatchInfoSerializer: Serializer<SerializedDispatchInfo, DispatchInfo> = {
  serialize(entity: DispatchInfo): SerializedDispatchInfo {
    return {
      ...entity,
      minDate: entity.minDate?.toISO() ?? undefined,
      selectedTimeSlot: entity.selectedTimeSlot
        ? {
            ...entity.selectedTimeSlot,
            startTime: entity.selectedTimeSlot.startTime.toISO() ?? '',
            endTime: entity.selectedTimeSlot.endTime.toISO() ?? '',
          }
        : undefined,
    };
  },
  deserialize(serialized: SerializedDispatchInfo): DispatchInfo {
    return {
      ...serialized,
      minDate: serialized.minDate ? DateTime.fromISO(serialized.minDate) : undefined,
      selectedTimeSlot: serialized.selectedTimeSlot
        ? {
            ...serialized.selectedTimeSlot,
            startTime: DateTime.fromISO(serialized.selectedTimeSlot.startTime),
            endTime: DateTime.fromISO(serialized.selectedTimeSlot.endTime),
          }
        : undefined,
    };
  },
};
