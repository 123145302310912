import model from './model';
import { PopularDishesController } from './popularDishesController';
import type { TFunction } from '@wix/yoshi-flow-editor';

export default model.createController(({ $bindAll, $w, $widget, flowAPI }) => {
  const t = flowAPI.translations.t as TFunction;

  const popularDishesController = new PopularDishesController($bindAll, $w, t);

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      popularDishesController.init();
    },
    exports: {},
  };
});
