import type { IHttpClient } from '@wix/yoshi-flow-editor';
import { listSections, getSection } from '@wix/ambassador-restaurants-menus-v1-section/http';

export class SectionsClient {
  httpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  fetchAllSections = async () => {
    try {
      return this.httpClient.request(listSections({})).then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getSectionById = async (sectionId: string) => {
    try {
      return this.httpClient.request(getSection({ sectionId })).then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };
}
