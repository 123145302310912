import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { SiteStructureWithHiddenFields } from './siteStructure';

const getBreadcrumbsConfig = (
  flowAPI: PlatformControllerFlowAPI,
  siteStructure: SiteStructureWithHiddenFields
) => {
  const { baseUrl } = flowAPI.controllerConfig.wixCodeApi.location;
  const { pages: sitePages } = siteStructure;
  const { t } = flowAPI.translations;

  const homePage = {
    name: t('app.breadcrumbs.labels.home'),
    url: '/',
  };
  const currentPageId = flowAPI.controllerConfig.wixCodeApi.site.currentPage?.id;
  const currentPage = sitePages.find((page) => page.id === currentPageId)!;

  return [
    {
      id: '1',
      value: homePage.name,
      href: `${baseUrl}${homePage.url}`,
    },
    {
      id: '2',
      value: currentPage.name ?? t('app.breadcrumbs.labels.olo'),
      href: `${baseUrl}${currentPage.url}`,
    },
  ];
};

export const generateBreadcrumbsSD = (
  flowAPI: PlatformControllerFlowAPI,
  siteStructure: SiteStructureWithHiddenFields
) => {
  const breadcrumbs = getBreadcrumbsConfig(flowAPI, siteStructure);
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: breadcrumb.value,
      item: breadcrumb.href,
    })),
  };
};
