import model from './model';
import { NavigationMenuController } from './navigationMenuController';

export default model.createController(({ $bindAll, $w, $widget, flowAPI }) => {
  const {
    environment: { isMobile },
  } = flowAPI;
  const navigationMenuController = new NavigationMenuController($w, $bindAll, isMobile);
  navigationMenuController.init();

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
