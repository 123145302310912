import { makeObservable, computed, observable, action, configure } from 'mobx';
import { DispatchType } from 'root/types/businessTypes';
import { dispatchState } from './DispatchState';

configure({ isolateGlobalState: true });

class FulfillmentPickerStore {
  shouldConsiderAllLocations = true;
  selectedDispatchType: DispatchType | undefined = undefined;
  onDispatchTypeChange?: (dispatchType: DispatchType) => Promise<void>;
  hasPopup: boolean | undefined = undefined;
  constructor() {
    makeObservable(this, {
      selectedDispatchType: observable,
      onDispatchTypeChange: observable,
      hasPopup: observable,
      shouldConsiderAllLocations: observable,
      availableDispatchTypes: computed,
      configuredDispatchTypes: computed,
      isOnlyPickupAvailable: computed,
      operation: computed,
      isLoading: computed,
      setObservableProps: action,
    });
  }

  setObservableProps({
    selectedDispatchType,
    onDispatchTypeChange,
    hasPopup,
    shouldConsiderAllLocations,
  }: {
    selectedDispatchType: DispatchType;
    onDispatchTypeChange: (dispatchType: DispatchType) => Promise<void>;
    hasPopup: boolean | undefined;
    shouldConsiderAllLocations?: boolean;
  }) {
    this.selectedDispatchType = selectedDispatchType;
    this.onDispatchTypeChange = onDispatchTypeChange;
    this.hasPopup = hasPopup;
    this.shouldConsiderAllLocations = !!shouldConsiderAllLocations;
  }

  get availableDispatchTypes() {
    return this.shouldConsiderAllLocations
      ? dispatchState.availableDispatchTypes
      : dispatchState.availableDispatchTypesForCurrentLocation;
  }

  get configuredDispatchTypes() {
    return this.shouldConsiderAllLocations
      ? dispatchState.configuredDispatchTypes
      : dispatchState.configuredDispatchTypesForCurrentLocations;
  }

  get operation() {
    return dispatchState.currentOperation;
  }

  get isMultiLocation() {
    return dispatchState.isMultiLocation;
  }

  get isLoading() {
    return dispatchState.isLoading ?? true;
  }

  get isOnlyPickupConfigured() {
    return (
      dispatchState.configuredDispatchTypes?.length === 1 &&
      dispatchState.configuredDispatchTypes.includes(DispatchType.PICKUP)
    );
  }

  get isOnlyPickupAvailable() {
    return (
      this.availableDispatchTypes?.length === 1 &&
      this.availableDispatchTypes.includes(DispatchType.PICKUP)
    );
  }
}

const fulfillmentPickerStore = new FulfillmentPickerStore();

export { fulfillmentPickerStore };
