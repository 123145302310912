import { OLO_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import type { OLOWidgetState } from '../../types/businessTypes';
import { context } from '../../context/RootContext';
import { menusState } from 'root/states/MenusState';
import { autorun } from 'mobx';
import { navigationMenuState } from 'root/states/NavigationMenuState';
import { hasDataChanged } from 'root/utils/utils';

export class OLOController {
  constructor(private $w: I$W, private t: TFunction) {
    context.pubsub.subscribe('onFetchFailed', this.switchState);
  }

  setMenus() {
    const menusElement = this.$w(OLO_WIDGET_COMPONENT_IDS.menuContainer);
    const headerElement = this.$w(OLO_WIDGET_COMPONENT_IDS.navigationMenu);
    autorun(() => {
      if (
        hasDataChanged({ prevData: menusElement.data.menus, nextData: menusState.sortedMenusDto })
      ) {
        menusElement.data = { menus: menusState.sortedMenusDto };
        navigationMenuState.setMenus(menusState.sortedMenusDto);
      }
    });
    autorun(() => {
      if (menusState.hasAtLeastOneMenuWithSections) {
        headerElement.collapsed && headerElement.expand();
      } else {
        !headerElement.collapsed && headerElement.collapse();
      }
    });
  }

  switchState = ({ oloState }: { oloState: OLOWidgetState }) => {
    const multiStateBox = this.$w(OLO_WIDGET_COMPONENT_IDS.oloMultiStateBox);
    multiStateBox.changeState(oloState);
    this.$w(OLO_WIDGET_COMPONENT_IDS.errorStateTitle).text = this.t('menu_olo.errorState.title');
    this.$w(OLO_WIDGET_COMPONENT_IDS.errorStateSubtitle).text = this.t(
      'menu_olo.errorState.subTitle'
    );
  };
}
