import { makeObservable, observable, action, configure, runInAction } from 'mobx';
import type { Item } from 'root/types/item';

configure({ isolateGlobalState: true });

class ItemState {
  itemMap: Map<string, Item> = new Map();
  isLoading = true;
  constructor() {
    makeObservable(this, {
      isLoading: observable,
      itemMap: observable,
      setItemMap: action,
      setIsLoading: action,
    });
  }

  // For unit test purposes
  _syncSetIsLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setIsLoading(loading: boolean) {
    setTimeout(() => {
      runInAction(() => {
        this.isLoading = loading;
      });
    }, 500);
  }

  setItemMap(items: Map<string, Item>) {
    this.itemMap = items;
  }
}

export const itemState = new ItemState();
