import type {
  PopulatedModifierGroup as PopulatedModifierGroupTPA,
  DisplayedModifier,
} from '@wix/restaurants-populated-item-client/types';

export type PopulatedModifierGroupLevel = { modifiers: true };
export type PopulatedModifierGroup = PopulatedModifierGroupTPA<PopulatedModifierGroupLevel>;

export type RepeaterModifierGroupListItem = Omit<PopulatedModifierGroup, 'modifiers'> & {
  modifierGroupId: string;
  modifiers: ModifierListItem[];
};
export type ModifierRepeaterData = PopulatedModifierGroup & {
  _id: number;
};
export type ModifierListItem = DisplayedModifier & { modifierId?: string };

export enum RuleType {
  NO_LIMIT,
  CHOOSE_ONE,
  CHOOSE_X,
  CHOOSE_AT_LEAST_ONE,
  CHOOSE_AT_LEAST_X,
  CHOOSE_UP_TO_X,
  CHOOSE_BETWEEN_X_AND_Y,
}

export type SelectedModifiers = Record<string, ModifierListItem[]>;
