import model from './model';
import { FulfillmentPickerController } from './FulfillmentPickerController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { fulfillmentPickerStore } from 'root/states/FulfillmentPickerStore';
import { autorun } from 'mobx';
import type { DispatchType } from 'root/types/businessTypes';
import { SPECS } from 'root/appConsts/experiments';

export default model.createController(({ $w, $widget, flowAPI, $bindAll }) => {
  const { translations, experiments } = flowAPI;
  const t = translations.t as TFunction;
  const enableMultiLocation = experiments.enabled(SPECS.enableMultiLocation);
  const fulfillmentPickerController = new FulfillmentPickerController($w, $bindAll);

  let didSetOnClickHandlers = false;

  const setOnClickHandlersIfNeeded = (
    onDispatchTypeChange?: (dispatchType: DispatchType) => void
  ) => {
    if (!didSetOnClickHandlers && onDispatchTypeChange) {
      fulfillmentPickerController.initButtons(
        enableMultiLocation && fulfillmentPickerStore.isMultiLocation
      );
      didSetOnClickHandlers = true;
    }
  };

  fulfillmentPickerController.initContainer(t);

  autorun(async () => {
    const {
      availableDispatchTypes,
      configuredDispatchTypes,
      selectedDispatchType,
      shouldConsiderAllLocations,
      isMultiLocation,
    } = fulfillmentPickerStore;
    await fulfillmentPickerController.initFulfillmentPicker(t, configuredDispatchTypes);
    await fulfillmentPickerController.setDispatchState(
      availableDispatchTypes,
      selectedDispatchType,
      enableMultiLocation && isMultiLocation && shouldConsiderAllLocations
    );
  });

  autorun(() => {
    const { isLoading, onDispatchTypeChange } = fulfillmentPickerStore;
    !isLoading && setOnClickHandlersIfNeeded(onDispatchTypeChange);
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
