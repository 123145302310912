import type { IHttpClient } from '@wix/yoshi-flow-editor';
import type { FedopsLogger } from '../utils/monitoring/FedopsLogger';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import type {
  MenuOrderingSettings,
  TimeSlot,
} from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/types';
import { OrdersSettingsClient } from 'root/api/ordersSettingsClient';
import type { ReportError } from 'root/api/utils/getMonitoredApiCall';
import type {
  DispatchInfo,
  MenusAvailabilityStatus,
  TimeSlotsPerMenu,
} from 'root/types/businessTypes';
import {
  convertMenuAvailabilityStatusArrayToKeyValue,
  convertTimeSlotsPerMenuArrayToKeyValue,
} from 'root/api/utils/utils';
import type { Address } from '@wix/ambassador-restaurants-operations-v1-operation/types';

export type OnErrorType = (error: Error) => void;
export type FetchMenusAvailabilityStatusByDispatchInfoParams = {
  operationId?: string;
  dispatchInfo: DispatchInfo;
  onError?: OnErrorType;
};
export type ListMenusAvailabilityStatusByTimeslotParams = {
  operationId: string;
  timeSlot: TimeSlot;
  onError?: OnErrorType;
};
export type GetMenuOrderingSettingsOperationIdParams = {
  operationId: string | undefined;
  onError?: OnErrorType;
};
export type ListFirstAvailableTimeSlotsForMenusParams = {
  operationId?: string;
  deliveryAddress?: Address;
  onError?: (error: Error) => void;
};

export interface IOrdersSettingsService {
  getMenuOrderingSettingsOperationId: ({
    operationId,
    onError,
  }: GetMenuOrderingSettingsOperationIdParams) => Promise<MenuOrderingSettings[]>;
  listMenusAvailabilityStatusByTimeslot: ({
    operationId,
    timeSlot,
    onError,
  }: ListMenusAvailabilityStatusByTimeslotParams) => Promise<MenusAvailabilityStatus>;
  fetchMenusAvailabilityStatusByDispatchInfo: ({
    operationId,
    dispatchInfo,
    onError,
  }: FetchMenusAvailabilityStatusByDispatchInfoParams) => Promise<MenusAvailabilityStatus>;
  fetchFirstAvailableTimeSlotsForMenus: ({
    deliveryAddress,
    operationId,
    onError,
  }: ListFirstAvailableTimeSlotsForMenusParams) => Promise<TimeSlotsPerMenu>;
}
export interface getMenuOrderingSettingsOperationIdParams {}

export const OrdersSettingsService = ({
  httpClient,
  fedopsLogger,
  sentry,
  reportError,
  experiments,
}: {
  httpClient: IHttpClient;
  fedopsLogger: FedopsLogger;
  sentry: ErrorMonitor;
  reportError?: ReportError;
  experiments: { enabled(key: string): boolean };
}): IOrdersSettingsService => {
  const ordersSettingsClient = new OrdersSettingsClient(
    httpClient,
    sentry,
    fedopsLogger,
    reportError
  );

  const ordersSettingsServiceObject = {
    getMenuOrderingSettingsOperationId: async ({
      operationId,
      onError,
    }: GetMenuOrderingSettingsOperationIdParams) => {
      const response = await ordersSettingsClient.getMenuOrderingSettingsOperationId(
        operationId,
        onError
      );
      return response.menuOrderingSettings || [];
    },
    listMenusAvailabilityStatusByTimeslot: async ({
      operationId,
      timeSlot,
      onError,
    }: ListMenusAvailabilityStatusByTimeslotParams) => {
      const response = await ordersSettingsClient.listMenusAvailabilityStatusByTimeslot(
        operationId,
        timeSlot,
        onError
      );
      return convertMenuAvailabilityStatusArrayToKeyValue(response.menusAvailabilityStatus) || {};
    },
    fetchMenusAvailabilityStatusByDispatchInfo: async ({
      operationId,
      dispatchInfo,
      onError,
    }: FetchMenusAvailabilityStatusByDispatchInfoParams) => {
      const { startTime, endTime } = dispatchInfo?.selectedTimeSlot || {};
      if (operationId && startTime && endTime) {
        const timeSlot = {
          startTime: startTime?.toJSDate(),
          endTime: endTime?.toJSDate(),
        };
        return ordersSettingsServiceObject.listMenusAvailabilityStatusByTimeslot({
          operationId,
          timeSlot,
          onError,
        });
      } else {
        return {};
      }
    },
    fetchFirstAvailableTimeSlotsForMenus: async ({
      deliveryAddress,
      operationId,
      onError,
    }: ListFirstAvailableTimeSlotsForMenusParams) => {
      const response = await ordersSettingsClient.listFirstAvailableTimeSlotsForMenus({
        operationId,
        deliveryAddress,
        onError,
      });
      return convertTimeSlotsPerMenuArrayToKeyValue(response.timeSlotsPerMenu) || {};
    },
  };

  return ordersSettingsServiceObject;
};
