import type { DispatchesInfo, DispatchState } from 'root/types/businessTypes';
import { DispatchType } from 'root/types/businessTypes';
import type { SerializedDispatchInfo } from './DispatchInfoSerializer';
import { DispatchInfoSerializer } from './DispatchInfoSerializer';
import type { Serializer } from './types';

export type SerializedDispatchState = Omit<DispatchState, 'dispatchesInfo'> & {
  dispatchesInfo: {
    [key in DispatchType]?: SerializedDispatchInfo;
  };
};

/**
 * Serializes and deserializes the DispatchState (specifically, handles DateTime serialization)
 */
export const DispatchStateSerializer: Serializer<SerializedDispatchState, DispatchState> = {
  serialize(entity: DispatchState): SerializedDispatchState {
    const dispatchesInfo = (Object.keys(DispatchType) as DispatchType[]).reduce(
      (info, key) => ({
        ...info,
        [key]: entity.dispatchesInfo[key]
          ? DispatchInfoSerializer.serialize(entity.dispatchesInfo[key])
          : undefined,
      }),
      {}
    );
    return { ...entity, dispatchesInfo };
  },
  deserialize(serialized: SerializedDispatchState): DispatchState {
    const dispatchesInfo = (Object.keys(DispatchType) as DispatchType[]).reduce(
      (info, key) => ({
        ...info,
        [key]: serialized.dispatchesInfo[key]
          ? DispatchInfoSerializer.deserialize(serialized.dispatchesInfo[key]!)
          : undefined,
      }),
      {} as DispatchesInfo
    );
    return { ...serialized, dispatchesInfo };
  },
};
