import type { DateTimeFormatOptions } from 'luxon';
import { DateTime } from 'luxon';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { SchedulingType } from 'root/types/businessTypes';
import type { DispatchType, DispatchTime, DispatchStateByLocation } from 'root/types/businessTypes';
import type { DurationRange } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import type { HeaderStore } from 'root/states/HeaderStore';
import type { TimeRangeForCart } from 'root/services/cartService';
import type { ASAPOptions } from './types';
import { context } from 'root/context/RootContext';
import { LiveSiteClickFulfillmentOrigin } from '@wix/restaurants-bi';
import { menusState } from 'root/states/MenusState';
import { dispatchState as _dispatchState } from 'root/states/DispatchState';

export const fullDateOptions: DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const wrapTimeInfoWithPrefix = (
  timeInfo: string,
  dispatchType: DispatchType,
  t: TFunction
) => {
  const prefix = t(`header_olo.time.${dispatchType}`);
  return `${prefix}${timeInfo}`;
};

export function isTomorrow(date: DateTime, timezone: string) {
  const tomorrow = DateTime.local({ zone: timezone }).startOf('day').plus({ days: 1 });
  return date.startOf('day').valueOf() === tomorrow.valueOf();
}

export function isToday(date: DateTime, timezone: string) {
  const today = DateTime.local({ zone: timezone }).startOf('day');
  return date.startOf('day').valueOf() === today.valueOf();
}

const getRangeString = ({ from, until, t }: { from: string; until: string; t: TFunction }) =>
  t('menu_olo.dispatch.time.simpleRange', { from, until });

export function getTimeString(time: DispatchTime, t: TFunction, useFullDateOptions = false) {
  if (time.from.valueOf() !== time.until.valueOf()) {
    return getRangeString({
      from: useFullDateOptions
        ? time.from.toLocaleString(fullDateOptions)
        : time.from.toLocaleString(DateTime.TIME_SIMPLE),
      until: time.until.toLocaleString(DateTime.TIME_SIMPLE),
      t,
    });
  }
  return useFullDateOptions
    ? time.from.toLocaleString(fullDateOptions)
    : time.from.toLocaleString(DateTime.TIME_SIMPLE);
}

export function convertTimeToTimeString({
  time,
  t,
  timezone,
}: {
  time: DispatchTime;
  timezone: string;
  t: TFunction;
}) {
  let timeString = '';
  if (isToday(time.from, timezone)) {
    timeString = t('menu_olo.dispatch.time.today', {
      time: getTimeString(time, t),
    });
  } else if (isTomorrow(time.from, timezone)) {
    timeString = t('menu_olo.dispatch.time.tomorrow', {
      time: getTimeString(time, t),
    });
  } else {
    timeString = getTimeString(time, t, true);
  }
  return timeString;
}

export const STATUS_INDICATOR_COLORS = {
  ONLINE: '#43B72A',
  OFFLINE: '#DF3131',
};

export const NOTIFICATION_BACKGROUND_COLORS = {
  INFO: '#FFB600',
  ERROR: '#DF3131',
};

export const BUTTON_STATES = {
  NEXT: 'nextBox',
  SAVE: 'saveBox',
};

export const getASAPString = (
  exactTime: number | undefined,
  timeRange: DurationRange | undefined,
  t: TFunction
) => {
  let timeString = '';

  if (typeof exactTime === 'number') {
    timeString = t('menu_olo.dispatch.time.upTo.minutes', {
      upTo: exactTime,
    });
  } else if (typeof timeRange !== 'undefined') {
    timeString = t('menu_olo.dispatch.time.range.minutes', {
      from: timeRange.minDuration,
      until: timeRange.maxDuration,
    });
  }

  return timeString;
};

export const resolveTimeString = ({
  asapOptions: { isASAP, exactTime, timeRange } = {} as ASAPOptions,
  selectedTime,
  t,
  timezone,
  dispatchType,
}: {
  asapOptions?: ASAPOptions;
  selectedTime?: DispatchTime;
  t: TFunction;
  timezone: string;
  dispatchType: DispatchType;
}) => {
  let timeString = '';
  if (isASAP && (typeof exactTime === 'number' || !!timeRange)) {
    timeString = getASAPString(exactTime, timeRange, t);
  } else if (selectedTime) {
    timeString = convertTimeToTimeString({
      time: selectedTime,
      t,
      timezone,
    });
  } else {
    return '';
  }
  return wrapTimeInfoWithPrefix(timeString, dispatchType, t);
};

export function getCartShippingDetailsFromHeaderStore(headerStore: HeaderStore) {
  let timeRange: TimeRangeForCart | undefined;

  if (headerStore.schedulingType !== SchedulingType.ASAP && headerStore.selectedTime) {
    timeRange = {
      start: headerStore.selectedTime.from.toUTC().valueOf(),
      end: headerStore.selectedTime.until.toUTC().valueOf(),
    };
  }
  return {
    locationId: headerStore.operation?.locationId || undefined,
    address: headerStore.selectedAddress,
    dispatchType: headerStore.dispatchType,
    timeRange,
  };
}

export const openDispatchModal = async (
  headerStore: HeaderStore,
  dispatchState: DispatchStateByLocation,
  selectedDispatchType?: DispatchType,
  isLoading?: boolean
) => {
  const currentCart = await context.CartService?.getCurrentCart();
  const cartLocationId = currentCart?.lineItems?.length
    ? currentCart?.businessLocationId
    : undefined;
  await context.ModalService?.openDispatchModal({
    dispatchState,
    isLoading,
    fedopsLogger: context.fedopsLogger,
    biReporterService: context.biReporterService,
    operations: _dispatchState.operations,
    selectedDispatchType: selectedDispatchType ?? headerStore.dispatchType,
    currentOperationId: _dispatchState.currentOperationId,
    isLocationSelected: _dispatchState.isLocationSelected,
    cartLocationId: cartLocationId || undefined,
    async onSave({ dispatchType, dispatchInfo, operationId, isLocationChanged }) {
      _dispatchState.setCurrentOperationId(operationId);
      _dispatchState.setIsLocationSelected(true);
      headerStore.updateDispatchState(dispatchType, dispatchInfo);
      if (isLocationChanged) {
        await context.CartService?.clearCart();
      }
      const shippingDetails = getCartShippingDetailsFromHeaderStore(headerStore);
      context.CartService?.setShippingDetails(shippingDetails);
      await menusState.updateAvailabilityStatus(
        _dispatchState.currentOperationId,
        dispatchInfo,
        dispatchType
      );
    },
  });
};

export const getOnDispatchTypeChange =
  (headerStore: HeaderStore, isMemberLoggedIn?: boolean) => async (dispatchType: DispatchType) => {
    context.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
      origin: LiveSiteClickFulfillmentOrigin.FULFILLMENT_TAB,
      dispatchType,
      isMemberLoggedIn,
    });
    const dispatchState = {
      dispatchStateByLocation: headerStore.dispatchState,
      selectedDispatchType: dispatchType,
      isLoading: _dispatchState.isLoading,
    };
    await openDispatchModal(
      headerStore,
      dispatchState.dispatchStateByLocation,
      dispatchType,
      _dispatchState.isLoading
    );
  };
